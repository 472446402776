import './PortfolioScreen.css';
import { Card, CardActionArea, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Grow, Tab, Tabs, Typography } from '@mui/material'
import CardMedia from '@material-ui/core/CardMedia';
import { useState } from 'react';
import profileData from '../utils/profileData';

const PortfolioScreen = () => {

    const [tabValue, setTabValue] = useState('All')
    const [projectDialog, setProjectDialog] = useState(false)


    return (
        <Grid container className='section p-4'>
            <Grid item>
                <Grid xs={12}>
                    <Typography className='text-title' variant='h6'> Portfolio </Typography>
                </Grid>

                {/* Tabs */}
                <Grid item xs={12}>
                    <Tabs value={tabValue} indicatorColor='white' className='tabs' onChange={(event, newValue) => setTabValue(newValue)}>
                        <Tab label="All" value="All" className={ tabValue === "All"? 'custom_tab active' :'custom_tab'} />

                        {[...new Set(profileData.projects.map((item) => item.tag))].map((tab) => (
                           <Tab label={tab} value={tab} className={ tabValue === tab ? 'custom_tab active' :'custom_tab'} z/>
                        ))}
                    </Tabs>
                </Grid>

                {/* Items */}
                <Grid xs={12}>
                <div className='root_grid'>
                    <Grid container justify="space-around" spacing={2}>
                        {
                            profileData.projects.map(project => (
                                <>
                                { 
                                tabValue === project.tag || tabValue === "All"? (
                                    
                                    <Grid item xs={12} sm={6} md={4}>
                                    <Grow in timeout={1000}>
                                        <Card
                                            className="project_card" onClick={() => {
                                                if(!projectDialog){
                                                    setProjectDialog(project)
                                                }}} >
                                        <CardActionArea className='card_action_area'>
                                            <CardMedia
                                                component="img"
                                                className='card_image'
                                                image={project.image}
                                                alt="Priject"
                                                />
                                                <CardContent>
                                                    <Typography component="div" gutterBottom className='card_title'>{project.title}</Typography>
                                                    <Typography gutterBottom variant='body2' className='card_caption'>{project.caption}</Typography>
                                                </CardContent>
                                        </CardActionArea>
                                        </Card>
                                    </Grow>
                                </Grid>
                                ) : null}
                            </>
                            ))
                        }
                        
                    </Grid>
                </div>
                </Grid>
            </Grid>

            <Dialog className="dialog" open={projectDialog} onClose={() => setProjectDialog(false)}>
                    <DialogTitle className="dialog_title">{projectDialog.title}</DialogTitle>
                        <img src={projectDialog.image} alt="project" className='dialog_image w-full' />
                    <DialogContent>
                        <Typography className='dialog_description'>
                        {projectDialog.description}
                        </Typography>
                    </DialogContent>
                    <DialogActions className="dialog_actions">
                        {
                        projectDialog?.links?.map((link) => (
                        <a href={link.link} className='dialog_icon' target="_blank" rel="noreferrer">
                            {link.icon}
                        </a>))
                        }
                    </DialogActions>
                </Dialog>
        </Grid>
    )
}

export default PortfolioScreen
