import React from 'react'
import { profileImage } from '../utils/assets'
import '../app.css'
import profileData from "../utils/profileData";
import CustomTimeline, { CustomLineSeprator } from './Timeline/Timeline'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { TimelineContent, TimelineItem } from '@mui/lab';
import { Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CustomButton from './Button/Button';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

const CustomTimelineItem = ({link, text, title}) =>(
    <TimelineItem>
        <CustomLineSeprator />
        <TimelineContent>
            <Fade left cascade>
            {link ? ( <Typography className='text-gray-600 break-all'>
                    <span className='text-black font-normal'>{title}:</span> {" "}<a className='hover:text-' rel="noopener noreferrer" href={link} target="_blank">
                        {text}
                    </a>
                </Typography>) : (
                <Typography  className='text-gray-600 break-all'>
                    <span className='text-black font-normal break-all'>{title}: </span>
                   <span className=' break-all'> {text}</span>
                </Typography>
            )}
            </Fade>
        </TimelineContent>
    </TimelineItem>
)

const Profile = () => {
    return (
            <div className='w-full'>
                <div className='text-xl text-h6 px-4 pt-4'>{profileData.name}</div>
                <div className='text-md px-4 pb-2'>{profileData.designation}</div>
                <figure className='profile_image'>
                    <Slide bottom cascade>
                        <img src={profileImage} alt='Profile' />
                    </Slide>
                </figure>

                <div className='profile_info'>
                    <CustomTimeline icon={<PersonOutlineIcon/>}>
                        <CustomTimelineItem title="Name" text={profileData.name}/>
                        <CustomTimelineItem title="Email" text={profileData.email}/>
                        <CustomTimelineItem title="Phone No" text={profileData.contactNo}/>
                        <CustomTimelineItem title="Job" text={profileData.job}/>

                        {Object.keys(profileData.social).map((key)=>(
                            <CustomTimelineItem title={key} text={profileData.social[key].text} link={profileData.social[key].link}/>
                        ))}
                    </CustomTimeline>
                </div>


                <div className='w-full mb-10 flex justify-center'>
                    <CustomButton text='Download CV' onClick={()=>window.open('https://jignesh.uniquecreators.in/Resume.pdf', 'Download')} icon={<FileDownloadIcon/>} />
                </div>
            </div>
    )
}

export default Profile
