import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isOpen: false,
    msg:"", 
}

//action
export const contactSlice = createSlice({
    name : "contact",
    initialState,
    reducers : {
        setIsOpen : async (state, action) => {
            state.isOpen = action.payload
        },

        setMsg: (state, action) => {
            state.msg = action.payload
        }
    }
})

export const {setIsOpen, setMsg} = contactSlice.actions

//selector
export const selectIsOpen = (state) => state.contact.isOpen
export const selectMsg = (state) => state.contact.msg

export default contactSlice.reducer