import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Nav, Navbar } from 'react-bootstrap';
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import profileData from '../../utils/profileData';
import CustomButton from './../Button/Button';
import './Header.css'
import HomeRounded from '@mui/icons-material/HomeRounded';
import Telegram from '@mui/icons-material/Telegram';
import { Grow } from '@mui/material';

const Header = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const pathName = location.pathname
    return (
        <Grow in timeout={1000}>
            <Navbar bg="light" expand="lg" className='header'>
                <Nav.Link as={NavLink} to='/' className='p-0 m-0'>
                    <Navbar.Brand className='header_home'>
                        <HomeRounded />
                    </Navbar.Brand>
                </Nav.Link>
                
                <Navbar.Toggle />

                <Navbar.Collapse className='sm:pl-2 lg:sm-pl-0'>
                    <Nav className='header_left'>
                        <Nav.Link
                            as={NavLink}
                            to='/'
                            className={pathName === "/"? 'header_link_active' : 'header_link'}>
                            Resume
                        </Nav.Link>

                        <Nav.Link
                            as={NavLink}
                            to='/portfolio'
                            className={pathName === "/portfolio"? 'header_link_active' : 'header_link'}>
                            Prtfolio
                        </Nav.Link>

                        <Nav.Link
                            as={NavLink}
                            to='/contact'
                            className={pathName === "/contact"? 'header_link_active' : 'header_link'}>
                            Contact Me
                        </Nav.Link>
                    </Nav>

                    <div className='header_right sm:pb-2 lg:pb-0'>
                        {Object.keys(profileData.social).map((key) => (
                            <a className='' rel="noopener noreferrer" target="_blank" href={profileData.social[key].link}>{profileData.social[key].icon}</a>
                        ))}
                        <CustomButton text="Hire Me" icon={<Telegram/>} onClick={()=>{
                            if(pathName !== "/contact") navigation("/contact")
                        }}/>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </Grow>
    )
}

export default Header
