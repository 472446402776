import { Typography } from '@material-ui/core';
import React from 'react'
import './SkillIndicator.css'
import Fade from 'react-reveal/Fade';

export const SkillIndicator = ({done, title}) => {
    const [style, setStyle] = React.useState({});
	
	setTimeout(() => {
		const newStyle = {
			opacity: 1,
			width: `${done}%`
		}
		setStyle(newStyle);	
	}, 200);

    return (
		<div className='progress_content'>
			<Typography variant='body' color=''>{title}</Typography>
			<div className='progress'>
				<Fade left>
					<div className='progress-done' style={style}>{done}%</div>
				</Fade>
			</div>
		</div>
        
    )
}
