import React from 'react'
import { CardContent, Grid, Icon, Typography} from '@material-ui/core'
import './ResumeScreen.css'
import profileData from '../utils/profileData'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SchoolIcon from '@mui/icons-material/School';
import CustomTimeline, { CustomLineSeprator } from '../components/Timeline/Timeline'
import {  TimelineContent, TimelineItem } from '@mui/lab';
import { Card } from '@mui/material';   
import { SkillIndicator } from '../components/SkillIndicator/SkillIndicator';
import 'animate.css';
import Fade from 'react-reveal/Fade';

const ResumeScreen = () => {
    return (
        <>
            {/* About me */}
            <Grid container className="section p-4">
                <Grid item className='section_title mb-3'>
                    <span></span>
                    <Typography  className='animate__animated  animate__fadeInUpBig text-main' variant='h6'><strong>About Me</strong></Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography className='animate__animated  animate__fadeInUpBig  aboutme_text'>{profileData.about}</Typography>
                </Grid>
            </Grid>

            {/* Education & Expreiance */}
            <Fade top>
            <Grid container className="section p-4">
                <Grid item className='section_title mb-3'>
                    <span></span>
                    <Typography className='animate__animated  animate__fadeInUpBig   text-main' variant='h6'><strong>Resume</strong></Typography>
                </Grid>

                <Grid item xs={12}>
                    <Grid container className='resume_timeline'>
                        {/* Experiance */} 
                        <Grid item sm={12} md={6}>
                            <CustomTimeline title="Work Experiance" icon={<BusinessCenterIcon/>}>
                                {profileData.epxeriances.map(epxeriance => (
                                    <TimelineItem>
                                        <CustomLineSeprator />
                                        <TimelineContent className='timeline_content'>
                                            <Fade left>
                                                <Typography className='timeline_title'>
                                                    <strong>
                                                        {epxeriance.title}
                                                    </strong>
                                                </Typography>
                                            </Fade>

                                            <Fade bottom>
                                                <Typography className='timeline_duration' variant='caption'>{epxeriance.duration}</Typography>
                                            </Fade>

                                            <Fade right>
                                                <Typography className='timeline_description' variant='body2'>{epxeriance.description}</Typography>
                                            </Fade>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </CustomTimeline>
                        </Grid>
                        {/* Education */}
                        <Grid item sm={12} md={6}>
                        <CustomTimeline title="Education" icon={<SchoolIcon/>}>
                                {profileData.educations.map(education => (
                                    <TimelineItem>
                                        <CustomLineSeprator />
                                        <TimelineContent className='timeline_content'>
                                            <Fade left>
                                                <Typography className='timeline_title'>
                                                    <strong>
                                                        {education.title}
                                                    </strong>
                                                </Typography>
                                            </Fade>

                                            <Fade top>
                                            <Typography className='timeline_duration' variant='caption'>{education.duration}</Typography>
                                            </Fade>

                                            <Fade right>
                                                <Typography className='timeline_description' variant='body2'>{education.institute}</Typography>
                                            </Fade>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </CustomTimeline>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Fade>

            {/* Services */}
            <Fade bottom>
                <Grid container className="section p-4">
                    <Grid item className='section_title mb-3'>
                        <span></span>
                            <Typography  className='text-main' variant='h6'>
                                <strong>
                                    My Services 
                                </strong>
                            </Typography>

                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="stretch" className='p-2 flex' spacing={1}>
                            {profileData.services.map(service => (
                                <Grid item className='grid_item' xs={12} sm={6} md={3} >
                                    <Fade top className="h-full">
                                    <Card elevation={0} className='card text-center service rounded-sm shadow-lg'>
                                        <CardContent>
                                            <Icon className='full-hw service_icon mt-4 justify-center self-center'>{service.icon}</Icon>
                                            <Typography className='service_title' variant='h6'>{service.title}</Typography>
                                            <Typography className='service_description mt-2' variant="body2">{service.description}</Typography>
                                        </CardContent>
                                    </Card>
                                </Fade>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    </Grid>
                </Fade>

            {/* Skills */}
            <Grid container className="section">
                <Grid item className='section_title mb-3'>
                    <span></span>
                    <Typography  className='text-main' variant='h6'>
                        <strong>
                            Skills 
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {
                            profileData.skills.map(skill => (
                                <Grid item xs={12} sm={12} md={6}>
                                    <SkillIndicator done={skill.percentage} title={skill.title} />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
       </>
    )
}

export default ResumeScreen
