// import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PublicIcon from "@mui/icons-material/Public";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import AttractionsIcon from "@mui/icons-material/Attractions";
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import PortfolioTabs from "../utils/PortfolioTabs";

const profileData = {
  name: "Jignesh Joshi",
  designation: "Software Engineer",
  birthDate: "12th sep 1997",
  email: "joshijignesh50@gmail.com",
  address:"G-1 Rajratna Recidency, near vav chockdi dharampur Valsad, 396050 Gujrat India",
  contactNo: "+91 9909366341, +91 9106030084",
  skype: "live:joshijignesh50",
  social: {
    LinkedIn: {
      link: "https://www.linkedin.com/in/joshijignesh50/",
      text: "Joshi Jignesh",
      icon: <LinkedInIcon />,
    },
    Github: {
      link: "https://github.com/joshijignesh",
      text: "JigneshJoshi",
      icon: <GitHubIcon />,
    },
    Skype: {
      link: "live:joshijignesh50",
      text: "joshijignesh50",
      icon: <PublicIcon />,
    },
    Instagram: {
      link: "https://www.instagram.com",
      text: "joshijignesh50",
      icon: <InstagramIcon />,
    },
    // facebook : {
    //     link : 'https://www.facebook.com',
    //     text: 'Joshi Jignesh',
    //     icon : <FacebookIcon />
    // },
    twitter : {
        link : 'https://x.com/481662e6601e4b1',
        text : 'JigneshJoshi',
        icon : <XIcon />
    },
  },
  footer: {
    footerDescription: "Hire Me For Interactive Software Design",
    createdBy: "Jignesh Joshi",
  },
  about: `I am Experienced Software Developer with over 4+ years of experience in creating and launching advanced mobile applications. Skilled in collaborating with cross-functional teams and solving problems to deliver high-quality solutions. Proficient in technologies including React Native, Kotlin, ReactJS, VueJS, NodeJS, and Laravel. Seeking a challenging role to enhance technical skills and contribute to an innovative organization’s success.`,
  epxeriances: [
    {
      title: "Alchemy Technologies Pvt. Ltd. | Android developer",
      duration: "May 2021– Present",
      description: `For good and better opportunities joined here and got some new good experiance over the coding in kotlin. deep dive in hilt, koin architecture of dagger.`,
    },
    {
      title: "Latitude Technolabs Pvt. Ltd. | Android developer",
      duration: "Oct 2019 – Apr 2021",
      description: `Latitude given me a lots of experiance as an professional. like good client communication,  Android MVVM, MVP, IoT Based Project with Beacons, Structured & Optimized Coding with Java & Kotlin, Custom C++ lib integration with Android Native
            Worked with React-Native, Ionic, Java (selenium) small tasks.`,
    },
    {
      title: "Cypherox Pvt. Ltd. | Jr. PHP developer",
      duration: "July 2019 – Sep 2019",
      description: `In this organization i have learned few things in beganing like
            Understand the client’s requirements and develop the website accordingly, Maintain the website, Optimizing the website, Manage the server etc.`,
    },
    {
      title: "Freelancing Laravel",
      duration: "Oct-2017 - Dec 2017",
      description: `I’ve developed a website as a freelancing project in Laravel PHP with socket.io. The project is based on crypto currency.`,
    },
    {
      title: "Peafowlsoft Pvt. Ltd. | Asp.Net Trainee",
      duration: "Jan 2016 – May 2016",
      description: `I’ve started my programming career by learning ASP.NET as a trainee in this
            company. Where I learned the basics of C#, ASP.NET web application, LINQ.
            `,
    },
  ],
  educations: [
    {
      title: "B.E. - Computer Engineering",
      duration: "Completed-2019",
      institute: "Merchant Engineering College, Basana (GTU)",
    },
    {
      title: "H.S.C",
      duration: "Completed-2015",
      institute: "R. A. Parmar Nilkanth Highschool (G.H.S.E.B)",
    },
    {
      title: "S.S.C",
      duration: "Completed-2013",
      institute: "Shree Swaminarayan Ramvadi School (G.S.E.B)",
    },
  ],
  services: [
    {
      title: "Mobile Development",
      description:
        "I have been working on mobile application from last 3 years and have good experiance of scalabale mobile Apps. With good coding and logical skills. I can develop Android/IOS and cross platform application.",
      icon: <TabletAndroidIcon />,
    },
    {
      title: "Web Development",
      description:
        "I have started my career as web developer so have very good experiance through it.",
      icon: <PublicIcon />,
    },
    /*{
            title: "Cross Platform Development",
            description: "I am good and quick learning so I have been epxerianced with lots of cross platforms technologies like vueJS, reactJS, flutter, iconic, angularJS etc. I can develop your software accourding to the required technology.",
            icon : <BlurOnIcon />
        },*/ {
      title: "UI/UX Design",
      description:
        "Everyboady want to a interative UI for there web or Apps. I can provide a very interactive and beutiful UI according to the requirements.",
      icon: <CatchingPokemonIcon />,
    },
    {
      title: "Graphics",
      description:
        "I am very crazy about graphics desgin from my college time photomanipulation, 3D graphics design with Maya, 3ds Max, cinema 4D.",
      icon: <AttractionsIcon />,
    },
  ],
  skills: [
    {
      title: "React Native",
      percentage: "92",
    },
    {
      title: "Kotlin",
      percentage: "84",
    },
    {
      title: "ReactJS",
      percentage: "87",
    },
    {
      title: "Flutter",
      percentage: "68",
    },
    {
      title: "Laravel",
      percentage: "81",
    },
    {
      title: "Vue Native",
      percentage: "74",
    },
    {
      title: "Adobe XD",
      percentage: "83",
    },
    {
      title: "Photoahop",
      percentage: "80",
    },
    {
      title: "Firebase",
      percentage: "92",
    },
    {
      title: "MySQL",
      percentage: "90",
    },
  ],
  projects: [
    {
      tag: PortfolioTabs.android,
      image:
        "https://jignesh.uniquecreators.in/zolve.jpg",
      title: "Zolve",
      description:
        "I got the opportunity to have some complicated tasks in this Application. Like Utility Bill Payment Feature, SBM, MX, Plaid Integration many other features, and design UI/UX as well.",
      caption:
        "Zolve is Mobile Credit Card Application. that serve the people's credit limit in dollar for NRI.",
      links: [{}],
    },
    {
      tag: PortfolioTabs.reactNative,
      image:
        "https://jignesh.uniquecreators.in/btc.jpg",
      description:
        "Blue Tokai Coffee is basically a coffee product and beans supplier with a bunch of cafe and warehouse branches. The application is about products and food serving like zomato and swiggy. there is 3 option for any user to get the product whatever they want PickUp, Delivery, DineIn. also there are multiple payment options as well.",
      title: "Blue Tokai Coffee",
      caption:
        "Blue Tokai Coffee is basically a coffee product and beans supplier with a bunch of cafe and warehouse branches.",
      links: [{}],
    },
    {
      tag: PortfolioTabs.reactNative,
      image:
        "https://jignesh.uniquecreators.in/boatersOS.jpg",
      description:
        "Boaters OS is a Boat management system where boat mane kan create a task to add/edit crew, service, and staff and show the individual person duty on the boat. Note: This Project is country spacific.",
      title: "Boaters OS Provider | Owner",
      caption:
        "Boaters OS is developed in React Native. This Project is a boat management system where staff and owner can define their service and the detail about crews.",
      links: [{}],
    },
    {
      tag: PortfolioTabs.android,
      image: "https://jignesh.uniquecreators.in/Authentic8_final.png",
      description:
        "Authentic 8 is the smart way of recognizing the products basis on QRCode, NFC Tag or LaavaID.",
      title: "Authentic 8",
      caption: "Authentic8 ia the smart way of recognizing the product.",
      links: [{}],
    },
    {
      tag: PortfolioTabs.react,
      image: "https://jignesh.uniquecreators.in/sp.png",
      description:
        "This site is into ReactJS with javascript language. This application is specifically for organizations to manage the visit with the receptionist, manager, and security to know the actual appointment meant for the right person.",
      title: "Scheduling Appointer",
      caption:
        "This site is into ReactJS with javascript language. This application is specifically for organizations to manage the visit with the receptionist, manager, and security to know the actual appointment meant for the right person.",
      links: [],
    },
    {
      tag: PortfolioTabs.android,
      image: "https://jignesh.uniquecreators.in/sat-braches.png",
      title: "SAT-Branches",
      description:
        "This application was created into Android Native with an MVVM structure. This application is based on CA work-based. there are 2 major roles where the maker will create the expense and income entries. the checker will check the entries and create they have facilities to create a CSV or exportable to hand over the created entries to CA. this application support online and offline feature for creating entires.",
      caption:
        "SAT-Branches is about managing expenses and incomes. This project is purely based on Koltin android with Mvvm Architecture.",
      links: [{}],
    },
    {
      tag: PortfolioTabs.android,
      image:
        "https://delraybeachexperience.com/wp-content/uploads/dbe-app-icon-final-light-blue-1080.png",
      title: "Delray Beach Experience",
      description:
        "This application is developed in Android Native with MVVM Standard. This application contains the local details about nearby shops and restaurants. Membership purchased users have more offers than the other ones.",
      caption:
        "Delray Beach Experience is basically about nearby available services and their offers. membership purchased users have more offers than the other ones.",
      links: [{}],
    },
    {
      tag: PortfolioTabs.reactNative,
      image:
        "https://delraybeachexperience.com/wp-content/uploads/dbe-app-icon-final-light-blue-1080.png",
      title: "Delray Beach Experience - React Native",
      description:
        "This application is developed in Android Native with MVVM Standard. This application contains the local details about nearby shops and restaurants. Membership purchased users have more offers than the other ones. After the first release, we developed this appplication into React-Native.",
      caption:
        "Delray Beach Experience is basically about nearby available services and their offers. membership purchased users have more offers than the other ones.",
      links: [{}],
    },
    {
      tag: PortfolioTabs.react,
      image:
        "https://res.cloudinary.com/practicaldev/image/fetch/s--54ca_F2q--/c_imagga_scale,f_auto,fl_progressive,h_900,q_auto,w_1600/https://dev-to-uploads.s3.amazonaws.com/i/1wwdyw5de8avrdkgtz5n.png",
      description:
        "This is the react projec of portfolio and CV to demostrate the professional profile",
      title: "My Resume - Jignesh Joshi",
      caption: "This is the smart resume for the professional.",
      links: [
        // { link: 'https://www.google.com', icon: <PublicIcon/>}
      ],
    },
    {
      tag: PortfolioTabs.react,
      image:
        "https://assets.brand.microsites.netflix.io/assets/7dc497e2-4975-11ec-a9ce-066b49664af6_cm_1440w.jpg?v=1",
      description:
        "This is the sample Apllication About how reacthook and reactJs workd. Just for learning perpose i have build this netflix clone",
      title: "Netflix Clone",
      caption:
        "This is the sample Apllication About how reacthook and reactJs workd. Just for learning perpose i have build this netflix clone",
      links: [
        { link: "https://netflix-clone-89fb6.web.app/", icon: <PublicIcon /> },
      ],
    },
    {
      tag: PortfolioTabs.vue,
      image: "https://miro.medium.com/max/850/1*nq9cdMxtdhQ0ZGL8OuSCUQ.jpeg",
      description:
        "Pacivity is VueJS firebase user authentic project where user can login via firebase and add some stocks entries and display the data into good user experiance maner.",
      title: "Pacivity",
      caption:
        "Pacivity is simply used to store some stock data and diplay it on good UI/UX to have good expeirance of data. This projet is based firebase cloud service.",
      links: [{}],
    },
    {
      tag: PortfolioTabs.reactNative,
      image: "https://miro.medium.com/max/1000/1*ub1DguhAtkCLvhUGuVGr6w.png",
      description:
        "The Signal is very popular application this days. I have created the clone of demostration of working of signal just a fun perpose.",
      title: "Signal Clone",
      caption: "Signal Application Clone",
      links: [{}],
    },
  ],
};

export default profileData;
