import { Typography } from '@material-ui/core'
import React from 'react'
import './Footer.css'
import profileData  from '../../utils/profileData'
const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer_left'>
                <Typography className="footer_name">
                    {profileData.footer.footerDescription}
                </Typography>
            </div>
            <div className='footer_right'>
                <Typography className='footer_copyright'>
                    © Design and Developed By {" "} <a href='/'>{profileData.footer.createdBy}</a>
                </Typography>
            </div>
        </div>
    )
}

export default Footer
