import React from 'react'
import Header from '../components/Header/Header'
import Profile from '../components/Profile'
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from "react-router-dom";
import ResumeScreen from './ResumeScreen';
import PortfolioScreen from './PortfolioScreen';
import Footer from '../components/Footer/Footer';
import ContactUsScreen from './ContactUsScreen';
import { Alert, Grow } from '@mui/material';
import 'animate.css';
import { Snackbar } from '@material-ui/core';
import { setMsg, setIsOpen, selectIsOpen, selectMsg } from '../slice/ContactUsSlice'
import { useDispatch, useSelector } from 'react-redux';

const HomeScreen = () => {
    const dispatch = useDispatch()

    const open = useSelector(selectIsOpen)
    const msg = useSelector(selectMsg)
    

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        dispatch(setIsOpen(false))
        dispatch(setMsg(""))
       
      };

    return (
        <div className='width-full md:py-20 sm:py-10 flex'>
            <div className='px-4 grid grid-cols-12 gap-10'>
                <Snackbar open={open} autoHideDuration={2000} sx={{ width: '100%' }} onClose={handleClose} >
                    <Alert severity="error" onClose={handleClose}>{msg}</Alert>
                </Snackbar>

                <Grow in timeout={1000}>
                    <div className='sm:col-span-12 md:col-start-2 lg:col-start-2 lg:col-span-3 md:col-span-3 flex rounded-xl container_shadow bg-white hover:bg-gray-50 h-fit-content'>
                    <Profile />
                    </div>
                </Grow>
                <div className='sm:col-span-12 lg:col-span-7 md:col-span-7'>
                    <Router>
                        <Header />
                        <div className='animate__animated  animate__fadeInUp  main-contenet container_shadow'>
                            <Routes>
                               <Route exact path="/" element={<ResumeScreen />} />
                               <Route path="/portfolio" element={<PortfolioScreen />}/>
                               <Route path="/contact" element={<ContactUsScreen />}/>
                            </Routes>
                        </div>
                    </Router>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default HomeScreen
