import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    count : 0
}

//action
export const countSlice = createSlice({
    name : "counter",
    initialState,
    reducers : {
        increaseCount : (state, action) => {
            state.count += action.payload
        },

        decreaseCount: (state, action) => {
            if(state.count < 0) state.count -= action.payload
        }
    }
})

export const {increaseCount, decreaseCount} = countSlice.actions

//selector
export const getCount = (state) => state.counter.count
export default countSlice.reducer