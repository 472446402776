import React from 'react'
import {Button} from '@material-ui/core'
import './Button.css'


const CustomButton = ({text, icon, onClick}) => {
    return (
    <Button 
        onClick={onClick}
        className='button_container' 
        endIcon={icon ? 
            (<div className='icon_container'>{icon}</div>) : null}>
        <span className='w-full text_container'>{text}</span>
    </Button>
    )
}

export default CustomButton
