import { createTheme } from "@mui/material";
import { Provider } from "react-redux";
import HomeScreen from "./pages/HomeScreen";
import { store } from "./store/store";
import 'animate.css';
import { ThemeProvider } from "@material-ui/core";
import {colorPrimary, colorSecondory} from './utils/colors'
require('dotenv').config()

const theme = createTheme({
  palette: {
    primary: {
      main: colorPrimary,
    },
    secondary: {
      main: colorSecondory,
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(',')
   },
   overrides : {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ["Montserrat"],
      },
    },
   }
 })

const App = () => {
 
  return (
    <Provider store={store}>
      <ThemeProvider  theme={theme}>
        <HomeScreen />
      </ThemeProvider >
    </Provider>
  );
}

export default App;
