import {Typography } from '@material-ui/core'
import { Grid } from '@mui/material'
import React from 'react'
import profileData from '../utils/profileData'
import './ResumeScreen.css'
import Fade from 'react-reveal/Fade';

// const inputPropsVal = {
//     autocomplete: 'new-password',
//     form: {
//       autocomplete: 'off',
//     },
//   }


const ContactUsScreen = () => {
    // const dispatch = useDispatch()
    // const [fullName, setFullName] = useState("")
    // const [email, setEmail] = useState("")
    // const [message, setMessage] = useState("")

    // const values = {
    //     fullName: fullName,
    //     email: email,
    //     message: message,
    //     from : "joshijignesh50@gmail.com"
    // }
    // const onClick = () => {
    //     if(fullName === ""){
    //         dispatch(setMsg("Please Select Provide you full name."))
    //         dispatch(setIsOpen(true))
    //         return
    //     }else if(email === ""){
    //         dispatch(setMsg(" Please Provide your e-mail ID."))
    //         dispatch(setIsOpen(true))
    //         return
    //     } else if(message === ""){
    //         dispatch(setMsg("Please write somthing to conntact with me."))
    //         dispatch(setIsOpen(true))
    //         return
    //     } else {
    //         sendEmail("Test", "joshijignesh50@gmail.com")
    //         return
    //     }
    // }

    const sendEmail = () => {
        // emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLETE_ID, values, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
        // .then((result) => {
        //     console.log(result.text);
        // }, (error) => {
        //     console.log(error.text);
        // });
    }
    
    return (
        <Grid container spacing={3} className='section p-4'>
            {/* <Grid xs={12} md={7} item className='section_title mb-3'>
                <Fade left>
                <Grid xs={12}>
                    <Typography className='text-main' variant='h6'>Contact Form</Typography>
                </Grid>

                <Grid container spacing={2} className='mt-2'>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            value={fullName}
                            onChange={ (e) =>{setFullName(e.target.value)}}
                            label="Full Name" 
                            className='w-full'
                            inputProps={inputPropsVal} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField 
                            value={email}
                            onChange={text => setEmail(text.target.value)}
                            label="E-mail" 
                            inputMode='email'
                            className='w-full' 
                            inputProps={inputPropsVal}/>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <TextField
                            value={message}
                            onChange={text => setMessage(text.target.value)}
                            className="w-full"
                            label="Message"
                            multiline
                            rows={4}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <CustomButton text="Submit" onClick={onClick} />
                    </Grid>
                </Grid>
                </Fade>
            </Grid> */}

            
            <Grid xs={12} md={12} item className='section_title  mb-8'>
                <span></span>
                <Fade right cascade>
                <Typography className='text-main' variant='h6'>
                    Contact Information
                </Typography>
                </Fade>

                <Grid container spacing={2} className='contactInfo mt-8'>
                    {/* <Grid item xs={12}>
                        <Fade right cascade>
                        <Typography className='contactInfo_item flex'>
                            <strong>Address : </strong> 
                            <span>{profileData.address}</span>
                        </Typography>
                        </Fade>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Fade right cascade>
                            <Typography className='contactInfo_item flex'>
                                <strong>Contact No : </strong>
                                <span>{profileData.contactNo}</span>
                            </Typography>
                        </Fade>
                    </Grid>

                    <Grid item xs={12}>
                        <Fade right cascade>
                            <Typography className='contactInfo_item flex'>
                                <strong>Email-ID : </strong> 
                                <span className='break-all'> {profileData.email}</span>
                            </Typography>
                        </Fade>
                    </Grid>

                    <Grid item xs={12}>
                        <Fade bottom cascade>
                            <Grid container className='contactInfo_socialContainer'>
                                {Object.keys(profileData.social).map(key=>(
                                    <Grid item className='contactInfo_item'>
                                        <a href={profileData.social[key].link} target="_blank" rel='noreferrer'>{profileData.social[key].icon}</a>
                                    </Grid>
                                ))}
                            </Grid>
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ContactUsScreen
